<template>
  <div class="mb-6 rounded-3xl border border-gray-500 p-6 text-white">
    <h1 class="mb-6 text-2xl font-semibold text-black dark:text-white">
      Previously Entered Contacts
    </h1>
    <div class="flex items-center justify-between">
      <div>
        <div class="grid grid-cols-4 gap-16">
          <div>
            <p class="text-sm text-gray-400">First Name:</p>
            <p class="break-words text-lg text-black dark:text-white">
              {{ lastUsedContact.first_name }}
            </p>
          </div>
          <div>
            <p class="text-sm text-gray-400">Last Name:</p>
            <p class="break-words text-lg text-black dark:text-white">
              {{ lastUsedContact.last_name }}
            </p>
          </div>
          <div>
            <p class="text-sm text-gray-400">E-mail Address:</p>
            <p class="break-words text-lg text-black dark:text-white">
              {{ lastUsedContact.email }}
            </p>
          </div>
          <div>
            <p class="text-sm text-gray-400">Phone number:</p>
            <p class="flex break-words text-lg text-black dark:text-white">
              {{ lastUsedContact.phone }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex space-x-4">
        <!-- <button class="button" @click="contactLastUsage">Select Previously Entered Contacts</button> -->
        <button @click="sideBarStore.update(true)" class="summary_edit_button w-80">
          Select Others
        </button>
      </div>
      <teleport to="body">
        <ChooseAllContact />
      </teleport>
    </div>
  </div>
</template>

<script setup>
import ChooseAllContact from './ChooseAllContact.vue'
import { useContactsStore } from '@/stores/contacts.js'
import { useSideBarStore } from '@/stores/sidebar.js'
import { useOrderStore } from '@/stores/order'
import { storeToRefs } from 'pinia'
import { watch, ref, onBeforeMount } from 'vue'

const orderStore = useOrderStore()
const sideBarStore = useSideBarStore()
const contactsStore = useContactsStore()
const { selectedContact } = storeToRefs(contactsStore)
const { orderData } = storeToRefs(orderStore)
const lastUsedContact = ref({})

onBeforeMount(() => {
  lastUsedContact.value = contactsStore.getLastUsage()
  emit('getContact', lastUsedContact.value)
})

watch(selectedContact, () => {
  lastUsedContact.value = selectedContact.value
})

watch(orderData, () => {
  lastUsedContact.value = orderData.value
})

const emit = defineEmits(['getContact'])

// function contactLastUsage() {
//   orderStore.update(contactsStore.getLastUsage())
// }
// function contactRemove(contact) {
//   contactsStore.remove(contact.first_name)
// }
</script>
<!-- xl: -->
<!-- <template>
  <div class="">
    <h1 class="title text-2xl">Previously Entered Contacts</h1>
    <div class="flex " v-for="(contact, index) in contactsData" :key="index">
      <p>{{ contact }}</p>
      <button class="button" @click="contactLastUsage(contact)">contactLastUsage</button>
      <button class="button" @click="contactRemove(contact)">contactRemove</button> 
    </div>
  </div>
</template> -->
