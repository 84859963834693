import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { collect } from 'collect.js'
import { createRouter, createWebHistory } from 'vue-router'
import { useOrderStore } from '@/stores/order'
import { useMainStore } from '@/stores/main'
import { useCarsStore } from '@/stores/cars'
import { useContactsStore } from '@/stores/contacts'
import { useFetcher } from '@/compose/axios'

import HomePage from '@/pages/HomePage.vue'
import ContactPage from '@/pages/ContactPage.vue'
import VehiclePage from '@/pages/VehiclePage.vue'
import SuccessPage from '@/pages/SuccessPage.vue'
import PaymentPage from '@/pages/PaymentPage.vue'
import ContactDataPage from '@/pages/ContactDataPage.vue'
import ServiceDataPage from '@/pages/ServiceDataPage.vue'
import NotAllowed from '@/templates/NotAllowed.vue'
import AlreadyPaid from '@/templates/AlreadyPaid.vue'
import PageNotFound from '@/templates/PageNotFound.vue'
import AccountInformationPage from '@/pages/account/AccountInformationPage.vue'
import SignInPage from '@/pages/account/SignInPage.vue'
import RideHistoryPage from '@/pages/account/RideHistoryPage.vue'
import MailCarTemplatePage from '@/pages/MailCarTemplatePage.vue'

import background from '~project_assets/images/background.jpg'
import moment from 'moment/moment'

const routes = [
  {
    path: '/',
    name: 'home',
    props: true,
    component: HomePage,
    meta: { title: 'Home - ' + import.meta.env.VITE_PROJECT_TITLE }
  },
  {
    path: '/contact',
    name: 'contact',
    props: true,
    component: ContactPage,
    meta: { title: 'Contact - ' + import.meta.env.VITE_PROJECT_TITLE }
  },
  {
    path: '/vehicle',
    props: true,
    name: 'vehicle',
    component: VehiclePage,
    meta: { title: 'Vehicle - ' + import.meta.env.VITE_PROJECT_TITLE }
  },
  {
    path: '/payment',
    name: 'mail_car',
    component: MailCarTemplatePage,
    meta: { title: 'Mail Link - ' + import.meta.env.VITE_PROJECT_TITLE }
  },
  {
    path: '/payment/:transaction_id',
    name: 'payment',
    component: PaymentPage,
    meta: { title: 'Payment - ' + import.meta.env.VITE_PROJECT_TITLE }
  },
  {
    path: '/success/:order_id/:payment_link/:payment_session',
    name: 'success_payment',
    component: SuccessPage,
    meta: { title: 'Success - ' + import.meta.env.VITE_PROJECT_TITLE }
  },
  {
    path: '/success/payment_intent/:order_id',
    name: 'success_payment_intent',
    component: SuccessPage,
    meta: { title: 'Success - ' + import.meta.env.VITE_PROJECT_TITLE }
  },
  {
    path: '/success',
    name: 'success',
    component: SuccessPage,
    meta: { title: 'Success - ' + import.meta.env.VITE_PROJECT_TITLE }
  },
  {
    path: '/contactData',
    name: 'contactData',
    component: ContactDataPage,
    meta: { title: 'Contact Data - ' + import.meta.env.VITE_PROJECT_TITLE }
  },
  {
    path: '/serviceData',
    name: 'serviceData',
    component: ServiceDataPage,
    meta: { title: 'Service Data - ' + import.meta.env.VITE_PROJECT_TITLE }
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: NotAllowed,
    meta: { title: 'Page is restricted - ' + import.meta.env.VITE_PROJECT_TITLE }
  },
  {
    path: '/alreadyPaid',
    name: 'alreadyPaid',
    component: AlreadyPaid,
    meta: { title: 'Request Already Paid - ' + import.meta.env.VITE_PROJECT_TITLE }
  },
  {
    path: '/account/accountinformation',
    name: 'accountinformation',
    component: AccountInformationPage,
    meta: { title: 'Account Information - ' + import.meta.env.VITE_PROJECT_TITLE }
  },
  {
    path: '/account/signin',
    name: 'signin',
    component: SignInPage,
    meta: { title: 'Sign in - ' + import.meta.env.VITE_PROJECT_TITLE }
  },
  {
    path: '/account/ridehistory',
    name: 'ridehistory',
    component: RideHistoryPage,
    meta: { title: 'Ride History - ' + import.meta.env.VITE_PROJECT_TITLE }
  },
  {
    path: '/:pathMatch(.*)*',
    component: PageNotFound,
    meta: { title: 'Page Not Found - ' + import.meta.env.VITE_PROJECT_TITLE }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to) => {
  document.title = to.meta.title
  document.body.style.backgroundImage = to.fullPath === '/' ? `url(${background})` : ''

  const contactsStore = useContactsStore()
  const orderStore = useOrderStore()
  const mainStore = useMainStore()
  const { ssid, flow, paymentCode, paymentLink, paymentSession, isRequesting } =
    storeToRefs(mainStore)
  const { orderData, orderId, fleet } = storeToRefs(orderStore)
  const carsStore = useCarsStore()
  const { cars } = storeToRefs(carsStore)
  const { axiosInstance } = useFetcher({ baseUrl: import.meta.env.VITE_APP_API_URL })
  const projectLink = ref(import.meta.env.VITE_PROJECT_URL)
  const formattedToday = moment().format('D.MM.Y')
  const updateOrder = async () => {
    const patchOrderData = {
      amount: orderData.value.total,
      payment_code: paymentCode.value,
      payment_date: formattedToday,
      user_add: parseInt(sessionStorage.getItem('user_add')),
      notes: orderData.value.notes,
      status: 5
    }

    if (orderId.value) {
      await axiosInstance
        .patch('/orders/' + orderId.value, patchOrderData)
        .then(function () {
          mainStore.$reset()
          contactsStore.add(orderData.value)
          router.push('/contactData')
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
  const getOrder = async () => {
    await axiosInstance
      .get('/orders/' + orderId.value)
      .then(function (response) {
        const orderDataKeys = collect(orderData.value).keys()
        const responseData = collect(response.data.data).only(orderDataKeys.toArray())
        orderStore.update(responseData.all())
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  const getSession = async () => {
    const getSessionParams = {
      session_id: paymentSession.value,
      payment_link: paymentLink.value,
      website: projectLink.value
    }

    await axiosInstance
      .post('/payment/session', getSessionParams)
      .then(function (response) {
        let paymentIntent = response?.data?.session?.payment_intent
        if (paymentIntent) {
          paymentCode.value = paymentIntent + '_aut'
          orderStore.update({ total: response?.data?.session?.amount_total / 100 })
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const query = collect(to.query)

  if (to.name === 'success_payment_intent') {
    let { payment_session, payment_link, order_id } = { ...to.params }
    let { payment_intent } = { ...to.query }

    if (payment_session) {
      orderId.value = order_id
      await getOrder().then(() => {
        paymentLink.value = payment_link
        paymentSession.value = payment_session
        getSession().then(() => {
          updateOrder()
        })
      })
    }

    if (payment_intent) {
      paymentCode.value = payment_intent + '_aut'
      await updateOrder()
    }

    // Clear Timer in Payment
    orderData.value.countdown = 'stop'
  }

  if (query.has('ssid')) {
    flow.value = 'mainsite'
    ssid.value = query.get('ssid')
  }

  if (query.has('ssid_cache')) {
    isRequesting.value = true
    const getData = await axiosInstance

      .get('/orders/cache/fetch/' + query.get('ssid_cache'))
      .then((response) => {
        isRequesting.value = false
        return response.data.data
      })
      .catch(function (error) {
        isRequesting.value = false
        console.log(error)
      })
    let orderItems = collect(getData).except('ssid').items
    orderStore.update(orderItems)

    collect(orderItems.allowedPages).each((e, k) => {
      if (e === 1) {
        router.push('/' + k)
      }
    })
  }

  if (!query.has('mail_car')) {
    const mutateDate = (date) => {
      let firstStep = date.split(' ')
      let secondStep = firstStep[0].split('/')
      let threeStep = [secondStep[1], secondStep[0], secondStep[2]].join('/')

      return [threeStep, firstStep[1]].join(' ')
    }

    if (!query.has('payment_intent')) {
      var data = query

      if (query.has('id')) {
        const getData = await axiosInstance

          .get('/orders/cache/fetch/' + query.get('id'))
          .then((response) => {
            return response.data.data
          })
          .catch(function (error) {
            console.log(error)
          })

        data = getData ? collect(getData) : collect(data)
      }

      if (data.has(['pickup', 'dropoff', 'date'])) {
        orderStore.$reset()
        carsStore.$reset()
        mainStore.$reset()
        orderStore.update({
          pickup: data.get('pickup'),
          dropoff: data.get('dropoff'),
          date_start: mutateDate(data.get('date')),
          entrypoint: data.get('tab_url'),
          sent_from: data.get('current_url'),
          type_of_service: 'oneWayTransfer',
          utm_source: data.get('utm_source'),
          utm_medium: data.get('utm_medium'),
          utm_campaign: data.get('utm_campaign'),
          utm_content: data.get('utm_content'),
          utm_term: data.get('utm_term'),
          redis_id: query.get('id')
        })
      }

      if (data.has(['pickup', 'duration', 'date'])) {
        orderStore.$reset()
        carsStore.$reset()
        mainStore.$reset()
        orderStore.update({
          pickup: data.get('pickup'),
          date_start: mutateDate(data.get('date')),
          distance: parseInt(data.get('duration')) * 20,
          entrypoint: data.get('tab_url'),
          sent_from: data.get('current_url'),
          hours: parseInt(data.get('duration')),
          type_of_service: 'hourlyAsDirected',
          utm_source: data.get('utm_source'),
          utm_medium: data.get('utm_medium'),
          utm_campaign: data.get('utm_campaign'),
          utm_content: data.get('utm_content'),
          utm_term: data.get('utm_term'),
          redis_id: query.get('id')
        })
      }

      if (data.get('tour', false) !== 'false' && data.get('tour', false)) {
        orderStore.$reset()
        carsStore.$reset()
        mainStore.$reset()
        orderStore.update({
          pickup: data.get('pickup'),
          reqs: data.get('requirments'),
          date_start: mutateDate(data.get('date')),
          entrypoint: data.get('tab_url'),
          sent_from: data.get('current_url'),
          hours: data.get('duration'),
          type_of_service: 'toursRoadshows',
          utm_source: data.get('utm_source'),
          utm_medium: data.get('utm_medium'),
          utm_campaign: data.get('utm_campaign'),
          utm_content: data.get('utm_content'),
          utm_term: data.get('utm_term'),
          redis_id: query.get('id')
        })

        axiosInstance.post('/orders', orderData.value).then(function (response) {
          orderStore.updateOrderId(response.data.data.id)
        })

        // axiosInstance
        //   .get('/cars')
        //   .then(function (response) {
        //     carsStore.update(response.data.data)
        //     console.log()
        //   })
        //   .catch(function (error) {
        //     console.log(error)
        //   })
      }
      if (data.get('fleet', false) !== 'false' && data.get('fleet', false)) {
        orderStore.update({
          car: data.get('car_index')
        })
        orderStore.updateFleet(true)

        //todo make request to localstorage
        axiosInstance
          .get('/cars')
          .then(function (response) {
            if (response.data.data && !cars.value) {
              carsStore.update(response.data.data)
            }
            const car = response.data.data.filter((e) => {
              return e.class_id === parseInt(data.get('car_index'))
            })
            carsStore.selectCar(car[0])
          })
          .catch(function (error) {
            console.log(error)
          })
      }

      if (data.get('tour', false) !== 'false' && data.get('tour', false)) {
        orderStore.updateTour(true)
      }
    }
  }

  if (query.has('mail_car')) {
    orderStore.updateMailCar(true)
    orderStore.updateOrderId(query.get('orderID'))
    isRequesting.value = true

    await axiosInstance

      .post('transaction/fetch/' + query.get('orderID'))

      .then(function (response) {
        axiosInstance
          .patch('/orders/' + query.get('orderID'), {
            car: query.get('car_index'),
            total: query.get('price'),
            amount: query.get('old_price'),
            mail_car: 1,
            step: 2
          })
          .then(function (responseMega) {
            const orderDataKeys = collect(orderData.value).keys()
            const responseData = collect(responseMega.data.data).only(orderDataKeys.toArray())

            let dataStore = { ...responseData.except(['date_start']).items }
            orderStore.update(dataStore)

            if (responseMega.data.data.priceChanged) {
              mainStore.updatePriceChanged(true)
            }
            router.push('payment/' + response.data.data.transaction_id)
          })
          .catch(function (error) {
            isRequesting.value = false
            console.log(error)
          })
      })
      .catch(function (error) {
        isRequesting.value = false
        console.log(error)
      })
  }

  if (
    to.name === 'contact' ||
    to.name === 'vehicle' ||
    to.name === 'success_payment_intent' ||
    to.name === 'success'
  ) {
    if (orderData.value.pickup) {
      if (orderData.value.type_of_service === 'toursRoadshows') {
        if (fleet.value) {
          orderData.value.allowedPages['contact'] = 1
        } else {
          orderData.value.allowedPages['vehicle'] = 1
        }
      } else {
        orderData.value.allowedPages['contact'] = 1
      }
    }

    if (!orderData.value.allowedPages[to.name]) {
      if (
        to.name === 'success_payment_intent' &&
        Object.prototype.hasOwnProperty.call(to.query, 'payment_intent') &&
        orderData.value.email != null
      ) {
        orderData.value.allowedPages[to.name] = 1
      } else {
        await router.push('forbiden')
      }
    }
  }
})
export default router
