<template>
  <div
    v-if="isLoggedIn"
    class="mr-[15px] mt-8 flex flex-row flex-wrap items-end justify-between pb-6 md:mt-[45px] md:pb-[64px]"
  >
    <h1 class="title mr-[15px] mt-0">Your Rides</h1>
  </div>
  <div v-if="isLoggedIn" class="flex flex-col justify-start xl:flex-row">
    <div class="left_side-wrapper">
      <div class="left_side">
        <router-link to="/account/accountinformation" class="account__button"
          >Account Information <span class="account__button__disc"></span
        ></router-link>
        <button type="button" class="account__button active">
          Ride History <span class="account__button__disc"></span>
        </button>
      </div>
      <button @click="logOut()" type="button" class="account__button text-logout_color mb-0">
        Log Out
      </button>
    </div>
    <div class="fake-orders-wrapper relative">
      <template v-if="!isRidesFetched">
        <div
          :class="{ ride: true, 'blur-sm': !isRidesFetched }"
          v-for="(fakeDataItem, index) in fakeData.slice(0, 4)"
          :key="index"
        >
          <div class="ride__top">
            <div class="ride__top__separator">
              <p class="ride_text">ID - {{ fakeDataItem.id }}</p>
              <p class="ride_text">Type of service - {{ fakeDataItem.typeOfService }}</p>
            </div>
            <div class="ride__top__separator">
              <p class="ride_text">Date - {{ fakeDataItem.date }}</p>
              <span class="ride_status waiting">Status - {{ fakeDataItem.status }}</span>
            </div>
          </div>
          <div class="ride__summary_button_wrapper">
            <div class="ride__summary_wrapper">
              <div class="summary_item_wrapper ride_summary_item_wrapper">
                <span class="summary_item_title ride_summary_title"
                  >Pick Up Location: {{ fakeDataItem.pickupLocation }}</span
                >
              </div>
              <div class="summary_item_wrapper ride_summary_item_wrapper">
                <span class="summary_item_title ride_summary_title"
                  >Drop-Off Location: {{ fakeDataItem.dropOffLocation }}</span
                >
              </div>
              <div class="summary_item_wrapper ride_summary_item_wrapper">
                <span class="summary_item_title ride_summary_title"
                  >Duration: {{ fakeDataItem.duration }}</span
                >
              </div>
              <div class="summary_item_wrapper ride_summary_item_wrapper !mb-0 md:!mb-6">
                <span class="summary_item_title ride_summary_title"
                  >Date:{{ fakeDataItem.date }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- Overlay -->
        <div class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center">
          <button @click="ridesHistoryStore.sendRequestBtn" class="button ride__button w-max">
            {{ buttonText }}
          </button>
        </div>
        <RideHistoryTimer
          v-if="isRequestPending"
          :totalSeconds="ridesHistoryStore.getSpendTime()"
          timer-css="account__button"
          title="Avg. time for importing rides histories"
        />
      </template>
      <template v-else>
        <div class="right_side-wrapper w-full">
          <div
            v-for="(history, index) in rides"
            :class="{ cancelled: history.status === 4 }"
            :style="styleCondition(history)"
            :key="index"
            class=""
          >
            <div class="ride__top" v-if="!isMobile">
              <div class="ride__top__separator">
                <p class="ride_text">ID - {{ history.id }}</p>
                <p class="ride_text">{{ typeOfService(history) }}</p>
              </div>
              <div class="ride__top__separator">
                <p class="ride_text">{{ history.created_at.replaceAll('T', ' ').split('.')[0] }}</p>
                <span v-if="history.status === 1" class="ride_status waiting">Created</span>
                <span v-if="history.status === 2" class="ride_status pending">Pending</span>
                <span v-if="history.status === 3" class="ride_status waiting"
                  >Waiting for Payment</span
                >
                <span v-if="history.status === 4" class="ride_status cancelled">Canceled</span>
                <span v-if="history.status === 5" class="ride_status done">Done</span>
              </div>
            </div>
            <div class="ride__top" v-if="isMobile">
              <div class="ride__top__separator !justify-between">
                <p class="ride_text">{{ typeOfService(history) }}</p>
                <span v-if="history.status === 1" class="ride_status waiting">Created</span>
                <span v-if="history.status === 2" class="ride_status pending">Pending</span>
                <span v-if="history.status === 3" class="ride_status waiting"
                  >Waiting for Payment</span
                >
                <span v-if="history.status === 4" class="ride_status cancelled">Canceled</span>
                <span v-if="history.status === 5" class="ride_status done">Done</span>
              </div>
            </div>
            <div class="ride__summary_button_wrapper">
              <div class="ride__summary_wrapper">
                <div
                  v-if="history.pickup != null"
                  class="summary_item_wrapper ride_summary_item_wrapper"
                >
                  <span class="summary_item_title ride_summary_title">Pick Up Location:</span>
                  <input
                    type="text"
                    :value="history.pickup"
                    class="summary_item_input ride_summary_input"
                    placeholder="Pick Up Location:"
                    disabled
                  />
                </div>
                <div
                  v-if="history.dropoff != null"
                  class="summary_item_wrapper ride_summary_item_wrapper"
                >
                  <span class="summary_item_title ride_summary_title">Drop-Off Location:</span>
                  <input
                    type="text"
                    :value="history.dropoff"
                    class="summary_item_input ride_summary_input"
                    placeholder="Drop Off Location"
                    disabled
                  />
                </div>
                <div
                  v-if="history.hours != null"
                  class="summary_item_wrapper ride_summary_item_wrapper"
                >
                  <span class="summary_item_title ride_summary_title">Duration:</span>
                  <input
                    type="text"
                    :value="history.hours"
                    class="summary_item_input ride_summary_input"
                    placeholder="Duration"
                    disabled
                  />
                </div>
                <div
                  v-if="history.date_start != null"
                  class="summary_item_wrapper ride_summary_item_wrapper !mb-0 md:!mb-6"
                >
                  <span class="summary_item_title ride_summary_title">Date:</span>
                  <input
                    type="text"
                    :value="history.date_start.split('.')[0].replaceAll('T', ' ')"
                    class="summary_item_input ride_summary_input"
                    placeholder="Date:"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="ride__top justify-between py-5">
              <p></p>
              <!-- More details-->
              <div class="flex gap-5">
                <button
                  class="ride_history_return_button whitespace-nowrap"
                  v-if="history.pickup && history.dropoff"
                  @click="formatHistoryData(history, 'return')"
                >
                  Book Return Ride
                </button>
                <button
                  class="ride_history_duplicate_button whitespace-nowrap"
                  @click="formatHistoryData(history, 'duplicate')"
                >
                  Duplicate Ride & Edit Time
                </button>
              </div>
            </div>
            <div
              v-if="
                history.consulting == null &&
                history.consulting == null &&
                history.distance == null &&
                history.transport == null
              "
            >
              <div class="ride__top mt-4 !border-none" v-if="isMobile">
                <div class="ride__top__separator !pb-2">
                  <p class="ride_text">ID - {{ history.id }}</p>
                </div>
                <div class="ride__top__separator !justify-between !pb-0 md:!justify-start md:!pb-4">
                  <p class="ride_text">
                    {{ history.created_at.replaceAll('T', ' ').split('.')[0] }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
  <h1 v-if="!isLoggedIn" class="title mr-[15px] mt-[5rem]">Please login to view ride history</h1>
  <router-link v-if="!isLoggedIn" to="/account/signin" class="button mt-[15px] w-fit"
    >Sign in
  </router-link>
  <swipe-modal
    v-model="isModal"
    v-if="isMobile"
    contents-height="60vh"
    border-top-radius="16px"
    background-color="ffffff"
    tip-color="#CDCFD0"
    @close="closeModal()"
  >
    <history-popup-content
      :consulting="userObj.consulting"
      :reqs="userObj.reqs"
      :distance="userObj.distance"
      :transport="userObj.transport"
    />
  </swipe-modal>
</template>

<script setup>
import swipeModal from '@takuma-ru/vue-swipe-modal'
import HistoryPopupContent from '@/components/HistoryPopupContent.vue'
import { computed, onBeforeMount, ref } from 'vue'
import { useRouter } from 'vue-router'
import moment from 'moment'
import { storeToRefs } from 'pinia'
import { useMobile } from '@/compose/ismobile'
//import { useCentrifugoStore } from '@/stores/centrifugo'
import { useUserStore } from '@/stores/user'
import { useOrderStore } from '@/stores/order'
import { useRidesHistoryStore } from '@/stores/rides-history'
import RideHistoryTimer from '@/components/RideHistoryTimer.vue'

const fakeData = ref([
  {
    id: 'XXXX1',
    typeOfService: 'XXXX1',
    date: 'XXXX1',
    status: 'XXXX1',
    pickupLocation: 'XXXX1',
    dropOffLocation: 'XXXX1',
    duration: 'XXXX1'
  },
  {
    id: 'XXXX2',
    typeOfService: 'XXXX2',
    date: 'XXXX2',
    status: 'XXXX2',
    pickupLocation: 'XXXX2',
    dropOffLocation: 'XXXX2',
    duration: 'XXXX2'
  },
  {
    id: 'XXXX3',
    typeOfService: 'XXXX3',
    date: 'XXXX3',
    status: 'XXXX3',
    pickupLocation: 'XXXX3',
    dropOffLocation: 'XXXX3',
    duration: 'XXXX3'
  }
])

const userStore = useUserStore()
const ridesHistoryStore = useRidesHistoryStore()
const orderStore = useOrderStore()

const {
  rides,
  isRidesFetched,
  isRequestSuccessful,
  isRequestPending,
  isRequestRefused,
  isNoRides,
  requestSent
} = storeToRefs(ridesHistoryStore)

let buttonText = computed(() => {
  if (isRequestSuccessful.value) return 'Succeed'
  if (isRequestPending.value) return 'Pending'
  if (isRequestRefused.value) return 'Refused'
  if (isNoRides.value && requestSent) return 'Rides histories empty'
  return 'Request Your Orders'
})

const { isLoggedIn } = storeToRefs(userStore)

const formatHistoryData = (history, type) => {
  orderStore.$reset()

  if (history.type_of_service === 'Tours / Roadshows') history.type_of_service = 'toursRoadshows'
  if (history.type_of_service === 'One Way Transfer') history.type_of_service = 'oneWayTransfer'
  if (history.type_of_service === 'Hourly as directed') history.type_of_service = 'hourlyAsDirected'

  orderStore.update({
    pickup: history.pickup,
    dropoff: history.dropoff,
    hours: history.hours,
    email: history.email,
    type_of_service: history.type_of_service,
    reqs: history.reqs,
    first_name: history.first_name,
    last_name: history.last_name,
    phone: history.phone,
    ride_history: true
  })

  if (type === 'return') returnRide(history)

  router.push({ name: 'home' })
}

const returnRide = (history) => {
  orderStore.update({
    dropoff: history.pickup,
    pickup: history.dropoff
  })
}

onBeforeMount(() => {
  ridesHistoryStore.loadRides()
  ridesHistoryStore.checkRequestSentOrNot()
})

const typeOfService = (order) => {
  const types = {
    oneWayTransfer: 'One Way Transfer',
    hourlyAsDirected: 'Hourly as directed',
    toursRoadshows: 'Tours/Roadshows'
  }

  return types[order.type_of_service]
}

function logOut() {
  userStore.preventLogout()
  setTimeout(function () {
    router.push('/account/signin')
  }, 100)
}

const { isMobile } = useMobile()

let isModal = ref(false)
const userObj = ref({
  consulting: '',
  reqs: '',
  distance: '',
  transport: ''
})

const router = useRouter()

const styleCondition = (order) => {
  if (
    (!checkTime(order, 'payment') && order.status === 3) ||
    (!checkTime(order, 'lead') && order.status === 2 && order.transport === null)
  )
    return { opacity: 0.5 }
  else return {}
}

const checkTime = (order, type) => {
  const now = moment()
  const orderCreated = moment(order.created_at)
  const diffInMinutes = now.diff(orderCreated, 'minutes')

  if (type === 'payment' && order.status === 3) {
    return diffInMinutes > 30
  }

  if (type === 'lead' && order.status === 2 && order.transport === null) {
    return diffInMinutes > 10
  }

  return false
}

const closeModal = () => {
  isModal.value = false
  document.querySelector('html').style.touchAction = 'auto'
  document.body.style.touchAction = 'auto'
  document.querySelector('html').style.overflowY = 'auto'
  document.body.style.overflowY = 'auto'
  document.querySelector('html').style.overscrollBehavior = 'auto'
  document.body.style.overscrollBehavior = 'auto'
}
</script>
