import { defineStore } from 'pinia'
import { ref } from 'vue'

const STORE_NAME = 'contacts'
const LOCALSTORAGE_KEY = 'contacts'

export const useContactsStore = defineStore(STORE_NAME, () => {
  const defaultData = []

  // {
  //     first_name: null,
  //     last_name: null,
  //     email: null,
  //     country_prefix: null,
  //     code: null,
  //     phone: null,
  //     last_usage: null
  // }

  const contactsData = ref(defaultData)
  const selectedContact = ref(null)

  function select(data) {
    selectedContact.value = data
  }

  function fill(data) {
    contactsData.value = data
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(data))
  }

  function add(order) {
    let find = contactsData.value.find((x) => {
      if (x.first_name == order.first_name && x.last_name == order.last_name) {
        return true
      }
      return false
    })

    if (!!find == false) {
      let contactsDataOld = contactsData.value

      let data = {
        // is_previously_enter: true,
        first_name: order.first_name,
        last_name: order.last_name,
        email: order.email,
        country_prefix: order.country_prefix,
        code: order.code,
        phone: order.phone,
        last_usage: new Date()
      }

      contactsDataOld.push(data)
      fill(contactsDataOld)

      return true
    }

    if (!!find == true) {
      contactsData.value.forEach((x) => {
        if (x.first_name == find.first_name && x.last_name == find.last_name) {
          x.last_usage = new Date()
        }
      })

      fill(contactsData.value)
    }

    return false
  }

  function getLastUsage() {
    if (contactsData.value.length == 0) return false

    return contactsData.value.reduce((a, b) => {
      return a.last_usage > b.last_usage ? a : b
    })
  }

  function remove(first_name) {
    fill(contactsData.value.filter((x) => x.first_name != first_name))
  }

  function $reset() {
    localStorage.removeItem(LOCALSTORAGE_KEY)
  }

  const loadDefaultValue = () => {
    const localStorageData = localStorage.getItem(LOCALSTORAGE_KEY)

    if (localStorageData) {
      fill(JSON.parse(localStorageData))
    } else {
      fill(defaultData)
    }
  }

  loadDefaultValue()

  return {
    contactsData,
    selectedContact,
    add,
    remove,
    fill,
    getLastUsage,
    select,
    $reset
  }
})
