<template>
  <div v-if="props.isOpen" class="fixed inset-0 z-50 flex items-center justify-center">
    <div class="fixed inset-0 bg-black opacity-50" @click="closePopUp"></div>
    <div
      class="relative z-10 w-full max-w-xl rounded-[35px] bg-white p-8 text-white shadow-lg dark:bg-[#272729]"
    >
      <h1 class="title mb-4 text-6xl font-bold dark:text-white">Sign In</h1>
      <p class="mb-6 text-black dark:text-white">
        To manage your rides more efficiently and quickly, please log in. It only takes a moment and
        will improve your experience on our site.
      </p>
      <form @submit.prevent="submitForm">
        <div class="mb-4 flex gap-4">
          <label class="w-full text-gray-600 dark:text-[#878787]" for="">
            E-mail Address
            <input
              type="email"
              class="input ym-record-keys w-full"
              placeholder="E-mail Address"
              v-model="email"
              required
            />
          </label>
        </div>
        <div class="mb-4">
          <label for="" class="w-full text-gray-600 dark:text-[#878787]">
            Password
            <input
              type="password"
              class="input ym-record-keys w-full"
              placeholder="Password"
              v-model="password"
              required
            />
          </label>
        </div>
        <button type="submit" class="button w-full">Sign In</button>
      </form>
      <p class="mt-4 text-center text-black dark:text-white">
        If you don't have an account, please
        <button @click="handleSignUpClick" type="button" class="text-green-400">
          sign up here
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue'
import { useUserStore } from '@/stores/user'
import { useRouter } from 'vue-router'

const email = ref('')
const password = ref('')

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const closePopUp = () => {
      emit('close')
    }

    const axios = inject('axios')
    const router = useRouter()
    const userStore = useUserStore()

    const projectLink = import.meta.env.VITE_PROJECT_URL

    const submitForm = () => {
      let data = {
        email: email.value,
        password: password.value,
        website: projectLink
      }

      axios
        .post('/auth/login', data)
        .then((response) => {
          if (response?.data?.status === 'success') {
            userStore.fill(response?.data?.data)
            router.push({ name: 'ridehistory' })
          } else {
            // error
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

    const handleSignUpClick = () => {
      closePopUp()
      emit('open-signup')
    }
    return {
      email,
      password,
      closePopUp,
      submitForm,
      handleSignUpClick
    }
  }
}
</script>

<style scoped>
/* Scoped styles if necessary */
</style>
