<template>
  <div
    :class="{ ' invisible opacity-0': !isSideBarOpen }"
    @click="sideBarStore.update(false)"
    class="fixed inset-0 left-0 top-0 z-50 flex w-screen justify-end bg-gray-800 bg-opacity-50 duration-300"
  >
    <div
      :class="{ ' translate-x-full': !isSideBarOpen }"
      @click.stop
      class="h-full w-4/12 rounded-2xl bg-white p-9 text-white duration-500 dark:bg-[#2B2D32]"
    >
      <div class="mb-4 flex items-center justify-between">
        <h2 class="text-2xl font-bold text-black dark:text-white">Choose contact</h2>
        <button
          @click="sideBarStore.update(false)"
          class="h-12 w-12 rounded-full border border-gray-600 text-[#5FD052]"
        >
          ✕
        </button>
      </div>
      <div class="space-y-4">
        <div
          v-for="(contact, index) in contactsData"
          :key="index"
          class="flex flex-wrap justify-between border-b border-gray-600"
        >
          <div class="mt-9">
            <div class="mb-2">
              <p class="text-[#878787] dark:text-[#C8C8C8]">First Name:</p>
              <span class="text-black dark:text-white"> {{ contact.first_name }}</span>
            </div>
            <div class="mb-2">
              <p class="text-[#878787] dark:text-[#C8C8C8]">E-mail Address:</p>
              <span class="text-black dark:text-white"> {{ contact.email }}</span>
            </div>
          </div>
          <div class="mr-40 mt-9">
            <div class="mb-2">
              <p class="text-[#878787] dark:text-[#C8C8C8]">Last Name:</p>
              <span class="text-black dark:text-white"> {{ contact.last_name }}</span>
            </div>
            <div class="mb-2">
              <p class="text-[#878787] dark:text-[#C8C8C8]">Phone number:</p>
              <span class="text-black dark:text-white">
                {{ contact.code }} {{ contact.phone }}</span
              >
            </div>
          </div>
          <!-- <button @click="contactsStore.select(contact)" :class="buttonClass(contact)" class="w-full py-2 mt-2 text-center rounded-lg text-black">Select Contact</button> -->
          <button @click="contactsStore.select(contact)" class="summary_edit_button mb-9 mt-6 h-9">
            Select Contact
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useContactsStore } from '@/stores/contacts.js'
import { watch, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useOrderStore } from '@/stores/order'
import { useSideBarStore } from '@/stores/sidebar.js'

const contactsStore = useContactsStore()
const { contactsData, selectedContact } = storeToRefs(contactsStore)
const orderStore = useOrderStore()
const { orderData } = storeToRefs(orderStore)

const sideBarStore = useSideBarStore()
const { isSideBarOpen } = storeToRefs(sideBarStore)

const activeContact = ref(null)

const updateActiveContact = (contact) => {
  activeContact.value = contact
}

// const buttonClass = (contact) => {
//   return contact === activeContact.value ? 'bg-green-400' : 'bg-red-500'
// }

watch(selectedContact, (newSelectedContact) => {
  if (newSelectedContact) {
    console.log('log watch(selectedContact)')
    updateActiveContact(newSelectedContact)
  }
})

watch(orderData, (newOrderData) => {
  if (newOrderData) {
    console.log('log watch(orderData)')
    updateActiveContact(newOrderData)
  }
})
</script>
