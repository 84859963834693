<template>
  <Form
    @submit="onSubmit"
    :class="classnames"
    v-slot="{ errors, isSubmitting }"
    :validation-schema="getFields"
  >
    <div class="summary_title_items_wrapper">
      <div class="flex w-full items-center gap-x-8 max-md:justify-between">
        <span class="summary_title">{{ props.title }}</span>
        <template v-if="props.disabled === 'false' && !fromServiceData">
          <button
            type="button"
            v-if="!summaryDataObject.isEditable"
            class="summary_edit_button px-3 py-1.5 md:hidden"
            @click="openEdit()"
          >
            Edit ride
          </button>
          <button
            :disabled="isSubmitting"
            type="submit"
            v-if="summaryDataObject.isEditable"
            class="summary_edit_button save min-w-max border-none px-3 py-1.5 md:hidden"
          >
            Confirm
          </button>
        </template>
      </div>
      <div
        class="flex w-full flex-row gap-x-[30px] max-md:flex-wrap md:gap-x-[60px]"
        :class="{ 'flex-wrap': summaryDataObject.isEditable }"
      >
        <div
          class="w-full gap-5 md:grid md:grid-flow-col md:grid-rows-2 md:gap-x-3 md:gap-y-4"
          :class="
            props.customStyle == 'true'
              ? 'mb-12 !flex justify-between max-[1230px]:flex-col'
              : 'flex flex-col'
          "
        >
          <div
            v-if="checkField('first_name')"
            class="summary_item_wrapper mb-0 h-max !max-w-none pb-0"
            :class="errors.first_name ? 'error' : ''"
          >
            <span class="summary_item_title">First Name:</span>
            <Field
              type="text"
              v-model="orderData.first_name"
              @beforeinput="utils.isLetter($event)"
              class="summary_item_input dark:bg-background border bg-[#E8EDE8]/50 disabled:border-[#878787] disabled:!text-[#878787] dark:border-[#3D4043] dark:!text-white dark:disabled:!text-[#878787]"
              maxlength="50"
              name="first_name"
              placeholder="First Name"
              :disabled="!summaryDataObject.isEditable"
            />
          </div>
          <div
            v-if="checkField('last_name')"
            class="summary_item_wrapper mb-0 h-max !max-w-none pb-0"
            :class="errors.last_name ? 'error' : ''"
          >
            <span class="summary_item_title">Last Name:</span>
            <Field
              type="text"
              v-model="orderData.last_name"
              @beforeinput="utils.isLetter($event)"
              class="summary_item_input dark:bg-background border bg-[#E8EDE8]/50 disabled:border-[#878787] disabled:!text-[#878787] dark:border-[#3D4043] dark:!text-white dark:disabled:!text-[#878787]"
              name="last_name"
              maxlength="50"
              placeholder="Last Name"
              :disabled="!summaryDataObject.isEditable"
            />
          </div>
          <div
            v-if="checkField('email')"
            class="summary_item_wrapper mb-0 h-max !max-w-none pb-0"
            :class="errors.email ? 'error' : ''"
          >
            <span class="summary_item_title">Email:</span>
            <Field
              type="text"
              v-model="orderData.email"
              class="summary_item_input dark:bg-background border bg-[#E8EDE8]/50 disabled:border-[#878787] disabled:!text-[#878787] dark:border-[#3D4043] dark:!text-white dark:disabled:!text-[#878787]"
              name="email"
              placeholder="Email"
              :disabled="!summaryDataObject.isEditable"
            />
          </div>
          <div v-if="checkField('code')" class="summary_item_wrapper mb-0 h-max !max-w-none pb-0">
            <span class="summary_item_title">Country code:</span>
            <input
              type="text"
              v-model="orderData.code"
              @beforeinput="utils.isNumber($event)"
              class="summary_item_input dark:bg-background border bg-[#E8EDE8]/50 disabled:border-[#878787] disabled:!text-[#878787] dark:border-[#3D4043] dark:!text-white dark:disabled:!text-[#878787]"
              name="code"
              placeholder="Country code"
              :disabled="!summaryDataObject.isEditable"
            />
          </div>
          <div v-if="checkField('phone')" class="summary_item_wrapper mb-0 h-max !max-w-none pb-0">
            <span class="summary_item_title">Phone:</span>
            <input
              type="text"
              v-model="orderData.phone"
              v-on:beforeinput="utils.isNumber($event)"
              class="summary_item_input dark:bg-background border bg-[#E8EDE8]/50 disabled:border-[#878787] disabled:!text-[#878787] dark:border-[#3D4043] dark:!text-white dark:disabled:!text-[#878787]"
              name="phone"
              placeholder="Phone"
              :disabled="!summaryDataObject.isEditable"
            />
          </div>
          <div
            v-if="checkField('pickup') && summaryDataObject.isEditable"
            class="summary_item_wrapper mb-0 h-max w-max pb-0"
            :class="{ error: errors.pickup }"
          >
            <span class="summary_item_title">Pick Up Location:</span>

            <Field :name="fields.pickup.name" v-model="orderData.pickup" v-slot="{ field }">
              <vue-google-autocomplete
                @placechanged="getAddressDataSummary"
                @focus="focusInput($event, 'pickup')"
                @beforeinput="beforeInput($event, 'pickup')"
                classname="!truncate max-w-[300px] summary-edit_item_input summary_item_input searchAutocomplete"
                v-bind="field"
                :placeholder="fields.pickup.placeholder"
                :autoCompleteString="orderData.pickup"
                types=""
                id="summary_1_pickup"
                maxlength="230"
                :disabled="!summaryDataObject.isEditable"
              >
              </vue-google-autocomplete>
            </Field>
          </div>
          <div
            class="summary_item_wrapper mb-0 h-max pb-0"
            v-else-if="checkField('pickup') && !summaryDataObject.isEditable"
          >
            <span class="summary_item_title">Pick Up Location:</span>
            <p
              class="summary_item_input text-background !truncate break-all !border-0 !p-0 !pb-2 dark:!text-white"
            >
              {{ orderData.pickup }}
            </p>
          </div>
          <div
            v-if="checkField('dropoff') && summaryDataObject.isEditable"
            class="summary_item_wrapper mb-0 h-max w-max pb-0"
            :class="{ error: errors.dropoff }"
          >
            <span class="summary_item_title">Drop-Off Location:</span>
            <Field
              :name="fields.dropoff.name"
              v-model="orderData.dropoff"
              :value="orderData.dropoff"
              v-slot="{ field }"
            >
              <vue-google-autocomplete
                @placechanged="getAddressDataSummary"
                @focus="focusInput($event, 'dropoff')"
                @beforeinput="beforeInput($event, 'dropoff')"
                classname="!truncate max-w-[300px] summary-edit_item_input summary_item_input searchAutocomplete"
                v-bind="field"
                :placeholder="fields.dropoff.placeholder"
                :autoCompleteString="orderData.dropoff"
                types=""
                id="summary_1_dropoff"
                maxlength="230"
                :disabled="!summaryDataObject.isEditable"
              >
              </vue-google-autocomplete>
            </Field>
          </div>
          <div
            class="summary_item_wrapper mb-0 h-max pb-0"
            v-else-if="checkField('dropoff') && !summaryDataObject.isEditable"
          >
            <span class="summary_item_title">Drop-Off Location:</span>
            <p
              class="summary_item_input text-background !truncate break-all !border-0 !p-0 !pb-2 dark:!text-white"
            >
              {{ orderData.dropoff }}
            </p>
          </div>
          <div
            v-if="checkField('reqs') && summaryDataObject.isEditable"
            class="summary_item_wrapper mb-0 h-max pb-0"
          >
            <span class="summary_item_title">Destinations & Requirements:</span>

            <Field
              type="text"
              v-model="orderData.reqs"
              class="summary_item_input"
              name="requirments"
              placeholder="Destinations & Requirements"
              :disabled="!summaryDataObject.isEditable"
            />
          </div>
          <div
            class="summary_item_wrapper mb-0 h-max pb-0"
            v-else-if="checkField('reqs') && !summaryDataObject.isEditable"
          >
            <span class="summary_item_title">Destinations & Requirements:</span>
            <p class="summary_item_input text-background break-all !border-0 !p-0 dark:!text-white">
              {{ orderData.reqs }}
            </p>
          </div>
          <div
            v-if="checkField('hours')"
            class="summary_item_wrapper mb-0 h-max pb-0"
            :class="errors.hours ? 'error' : ''"
          >
            <span class="summary_item_title">Duration:</span>
            <div class="flex flex-row items-center justify-start">
              <Field
                type="text"
                v-model="orderData.hours"
                v-on:beforeinput="utils.isNumber($event)"
                class="summary_item_input"
                name="hours"
                placeholder="Duration"
                :disabled="!summaryDataObject.isEditable"
              />
              <span class="summary_item_input_sibling ml-[2px]">hours</span>
            </div>
          </div>
          <div
            v-if="checkField('date_start') && summaryDataObject.isEditable"
            class="summary_item_wrapper mb-0 h-max w-max pb-0"
          >
            <span class="summary_item_title">Date:</span>
            <VueDatePicker
              ref="datePickerRef"
              placeholder="Date / Time"
              :preview-format="dateFormat"
              :disabled="!summaryDataObject.isEditable"
              :clearable="false"
              :format="dateFormat"
              :dark="mode === 'dark'"
              :light="mode === 'light'"
              name="date_start"
              v-model="inputDate"
              @internal-model-change="handleInternal"
              :min-date="minDate"
              :min-time="minTime"
            >
              <template #right-sidebar>
                <div class="overflow-y-scroll px-3 py-2" style="max-height: 300px">
                  <div
                    v-for="time in timeOptions"
                    :key="time"
                    @click="setTime(time)"
                    :class="[
                      'time-option cursor-pointer',
                      time === selectedTime ? 'time-selected' : ''
                    ]"
                  >
                    {{ time }}
                  </div>
                </div>
              </template>
            </VueDatePicker>
          </div>
          <div
            v-if="checkField('date_start') && !summaryDataObject.isEditable"
            class="summary_item_wrapper mb-0 h-max w-max shrink-0 pb-0"
          >
            <span class="summary_item_title">Date:</span>
            <p class="summary_item_input text-background break-all !border-0 !p-0 dark:!text-white">
              {{ inputDate }}
            </p>
          </div>
          <div v-if="checkField('distance')" class="summary_item_wrapper mb-0 h-max w-max pb-0">
            <span class="summary_item_title">Distance:</span>
            <div class="flex flex-row items-center justify-start">
              <span class="summary_item_input_sibling">{{ roundedDistance }} KMS </span>
            </div>
          </div>

          <div
            v-if="checkField('car') && selectedCar"
            class="summary_item_wrapper mb-4 h-max w-min pb-0 md:mb-0 md:pb-[20px]"
          >
            <span class="summary_item_title">Car:</span>
            <div class="flex flex-row items-center justify-start">
              <span class="summary_item_input_sibling w-max">{{
                selectedCar.slug_class_name
              }}</span>
              <div v-if="!isMobile" class="popup_opened group relative py-2 md:ml-2">
                <div
                  class="absolute -right-[-60px] -top-[500px] z-40 hidden drop-shadow-popup group-hover:block"
                >
                  <car-popup-content />
                </div>
                <span class="summary_view_button cursor-pointer">View</span>
              </div>
              <div v-if="isMobile" class="ml-2 py-2 leading-[1]">
                <button @click="openModal()" class="summary_view_button">View</button>
              </div>
            </div>
          </div>
          <template v-if="props.disabled === 'false' && !paymentVisited && !fromServiceData">
            <div class="">
              <button
                type="button"
                v-if="!summaryDataObject.isEditable"
                :class="props.customStyle == 'true' ? 'mb-5 !w-[260px]' : 'w-full'"
                class="summary_edit_button h-16 w-full min-w-max self-end justify-self-end px-3 py-1.5 max-md:hidden"
                @click="openEdit()"
              >
                Edit ride
              </button>
              <button
                :disabled="isSubmitting"
                type="submit"
                v-if="summaryDataObject.isEditable"
                :class="props.customStyle == 'true' ? 'mb-5 !w-[260px]' : 'w-full'"
                class="summary_edit_button save h-16 w-full min-w-max self-end justify-self-end border-none px-3 py-1.5 max-md:hidden"
              >
                Confirm
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
    <slot></slot>
  </Form>
  <swipe-modal
    v-model="summaryDataObject.isModal"
    v-if="(isMobile && !mailCar) || !mailCar"
    contents-height="60vh"
    border-top-radius="16px"
    background-color="ffffff"
    tip-color="#CDCFD0"
    @close="closeModal()"
  >
    <car-popup-content />
  </swipe-modal>
</template>
<script setup>
import CarPopupContent from '@/components/CarPopupContent.vue'
import VueGoogleAutocomplete from '@/components/custom/VueGoogleAutocomplete.vue'
import { useDatePicker } from '@/compose/datePicker'
import { useGoogleAddress } from '@/compose/googleAddress'
import { useMobile } from '@/compose/ismobile'
import { useCarsStore } from '@/stores/cars'
import { useMainStore } from '@/stores/main'
import { useOrderStore } from '@/stores/order'
import { useProfileCompletionStore } from '@/stores/profile_completion'
import swipeModal from '@takuma-ru/vue-swipe-modal'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { collect } from 'collect.js'
import moment from 'moment'
import { storeToRefs } from 'pinia'
import { Field, Form } from 'vee-validate'
import { computed, inject, onMounted, reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import * as yup from 'yup'

const mainStore = useMainStore()
const carsStore = useCarsStore()
const orderStore = useOrderStore()
const profileCompletionStore = useProfileCompletionStore()
const { orderData } = storeToRefs(useOrderStore())

const { mailCar, orderId } = storeToRefs(orderStore)
const { fromServiceData } = storeToRefs(profileCompletionStore)
const { mode, isRequesting } = storeToRefs(mainStore)

const { selectedCar } = storeToRefs(carsStore)
const axios = inject('axios')
const utils = inject('utils')

const regexLink = inject('regexLink')
const regexIsHttps = inject('regexIsHttps')
const regexNameField = inject('regexNameField')

const route = useRoute()

const props = defineProps(['title', 'customStyle', 'disabled', 'classnames', 'prepare'])

const inputDate = computed({
  get: () => {
    return moment(orderData.value.date_start).format('YYYY-MM-DD HH:mm')
  },
  set: (value) => {
    orderStore.update({ date_start: moment(value).format('YYYY-MM-DD HH:mm') })
  }
})

const preparedFields = {
  serviceData: ['pickup', 'dropoff', 'date_start', 'hours', 'reqs', 'distance', 'duration', 'car'],
  contactData: ['first_name', 'last_name', 'email', 'code', 'phone'],
  contact: ['pickup', 'dropoff', 'date_start', 'hours', 'reqs', 'car'],
  vehicle: ['pickup', 'dropoff', 'date_start', 'hours', 'reqs', 'distance', 'duration'],
  payment: ['pickup', 'dropoff', 'date_start', 'hours', 'reqs', 'distance', 'duration', 'car']
}

const pudoChanged = ref(false)
const getAddressDataSummary = (addressData, placeResultData) => {
  if (orderData.value.type_of_service === 'oneWayTransfer') {
    getAddressData(addressData, placeResultData)
    pudoChanged.value = true
  } else {
    getAddressData(addressData, placeResultData)
  }
}

const sourceFields = computed(() => {
  return props.prepare ?? route.name
})

const checkField = (field) => {
  return collect(preparedFields[sourceFields.value]).some(field) && orderData.value[field] != null
}

const getFields = computed(() => {
  const fields = preparedFields[sourceFields.value]
  const mapped = fields.filter((e) => checkField(e))

  const keys = collect(myObject).only(mapped).all()
  return yup.object(keys)
})

let summaryData = {
  isModal: false,
  isEditable: false,
  dateInputStyles: {
    pointerEvents: 'none'
  }
}
let summaryDataObject = reactive(summaryData)

const { fields, beforeInput, focusInput, getAddressData } = useGoogleAddress('summary')

const {
  datePickerRef,
  timeOptions,
  minDate,
  minTime,
  datePicker,
  handleInternal,
  dateFormat,
  initialDate
} = useDatePicker()

const selectedTime = ref('')

const setTime = (time) => {
  const [hours, minutes] = time.split(':').map(Number)
  if (initialDate.value) {
    let updatedDate = new Date(initialDate.value)
    updatedDate.setHours(hours, minutes)
    datePicker.value = moment(updatedDate).format('YYYY-MM-DD HH:mm')
    orderData.value.date_start = moment(updatedDate).format('YYYY-MM-DD HH:mm')
  }
  selectedTime.value = time

  datePickerRef.value.closeMenu()
}

const { isMobile } = useMobile()

const myObject = {
  email: yup
    .string()
    .required()
    .email()
    .max(230)
    .matches(regexIsHttps, { excludeEmptyString: true })
    .matches(regexLink, { excludeEmptyString: true }),
  first_name: yup
    .string()
    .required()
    .max(50)
    .matches(regexNameField, { excludeEmptyString: true })
    .matches(regexIsHttps, { excludeEmptyString: true })
    .matches(regexLink, { excludeEmptyString: true }),
  last_name: yup
    .string()
    .required()
    .max(50)
    .matches(regexNameField, { excludeEmptyString: true })
    .matches(regexIsHttps, { excludeEmptyString: true })
    .matches(regexLink, { excludeEmptyString: true }),
  hours: yup.number().required().max(10),
  pickup: yup
    .string()
    .required()
    .max(230)
    .test(
      'google-complete',
      'Address must be verified using Google Autocomplete, and the country must be within the European Union or Switzerland',
      (value, ctx) => {
        return fields.value[ctx.path].valid
      }
    )
    .matches(regexIsHttps, { excludeEmptyString: true })
    .matches(regexLink, { excludeEmptyString: true }),
  dropoff: yup
    .string()
    .required()
    .max(230)
    .test(
      'google-complete',
      'Address must be verified using Google Autocomplete, and the country must be within the European Union or Switzerland',
      (value, ctx) => {
        return fields.value[ctx.path].valid
      }
    )
    .matches(regexIsHttps, { excludeEmptyString: true })
    .matches(regexLink, { excludeEmptyString: true })
}

const changeButtonColor = (mode) => {
  if (mode === 'light') {
    summaryDataObject.dateInputStyles = {
      pointerEvents: 'all',
      color: 'rgb(45 132 35 / 1)',
      borderBottom: '1px solid rgb(45 132 35 / 1)'
    }
  } else {
    summaryDataObject.dateInputStyles = {
      pointerEvents: 'all',
      color: 'rgb(204 242 200 / 1)',
      borderBottom: '1px solid rgb(204 242 200 / 1)'
    }
  }
}

const openEdit = () => {
  summaryDataObject.isEditable = true
  changeButtonColor(mode.value)
}
watch(mode, (newMode) => {
  changeButtonColor(newMode)
})
const onSubmit = async (values) => {
  values.code = orderData.value.code
  values.phone = orderData.value.phone
  values.country_prefix = orderData.value.country_prefix
  values.date_start = orderData.value.date_start
  values.contact_id = orderData.value.contact_id
  values.type_of_service = orderData.value.type_of_service

  if (orderData.value.type_of_service === 'hourlyAsDirected') {
    values.distance = values.hours * 20
  }

  orderStore.update(values)

  if (orderId.value !== 'null' && orderId.value) {
    isRequesting.value = true
    await axios
      .post('/orders/update/orderSummary/' + orderId.value, values)
      .then(function (response) {
        if (
          response.data.data.cars !== undefined &&
          response.data.data.cars !== null &&
          response.data.data?.cars.length > 0
        ) {
          let new_cars = response.data.data.cars
          carsStore.update(new_cars)
        }
        if (response.data.data.order !== undefined && response.data.data.order !== null) {
          let data_order = response.data.data.order
          orderStore.updateOrder(data_order)
          orderStore.updateOrderId(data_order.id)
          orderStore.update({ distance: data_order.distance })

          if (pudoChanged.value) {
            axios.post('/fields/search/' + orderId.value, {}).then(() => {})
          }
          isRequesting.value = false
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  if (orderData.value.deal_id) {
    await axios
      .post('/success/editContact', values)
      .then((/*response*/) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log(error)
      })
  }

  summaryDataObject.isEditable = false
  summaryDataObject.dateInputStyles = {
    pointerEvents: 'none',
    color: 'unset',
    borderCollor: 'transparent'
  }
}

onMounted(() => {})

const openModal = () => {
  summaryDataObject.isModal = true
  document.querySelector('html').style.touchAction = 'none'
  document.body.style.touchAction = 'none'
  document.querySelector('html').style.overflowY = 'hidden'
  document.body.style.overflowY = 'hidden'
  document.querySelector('html').style.overscrollBehavior = 'none'
  document.body.style.overscrollBehavior = 'none'
}

const closeModal = () => {
  summaryDataObject.isModal = false
  document.querySelector('html').style.touchAction = 'auto'
  document.body.style.touchAction = 'auto'
  document.querySelector('html').style.overflowY = 'auto'
  document.body.style.overflowY = 'auto'
  document.querySelector('html').style.overscrollBehavior = 'auto'
  document.body.style.overscrollBehavior = 'auto'
}

const roundedDistance = computed(() => {
  let distance = parseFloat(orderData.value.distance)
  console.log(orderStore)
  if (distance >= 0 && distance <= 5) {
    return `${Math.ceil(distance)}`
  } else if (distance > 5 && distance <= 10) {
    return `${Math.floor(distance)}`
  } else {
    return `${Math.round(distance / 10) * 10}`
  }
})
</script>
